export const testimonial_data = [
	{
	title: 'Marketing Manager',
	desc: "Working with influencer marketing has been a game-changer for our company. The results we've seen have exceeded our expectations, and we couldn't be happier with the ROI. Thank you to the team at InfluencerX for their exceptional work!",
	id: '0Marketing Manager',
	name: 'John Davis',
	img: '/images/testimonials/testimonial_1.jpg',
	},
	{
	title: 'Executive',
	desc: "Influencer marketing has been instrumental in helping us grow our brand and reach new audiences. We were able to connect with influencers who resonated with our brand, and as a result, we saw a significant increase in sales. We highly recommend working with the team at InfluencerHub.",
	id: '1Executive',
	name: 'Emily Chen',
	img: '/images/testimonials/testimonial_1.jpg',
	},
	{
	title: 'Social Media Manager at DEF Corporation',
	desc: "We were hesitant to try influencer marketing at first, but after seeing the results, we're glad we did. Our social media engagement and brand awareness have both improved significantly, and we attribute much of that to our partnership with InfluencerNow.",
	id: '2Social Media Manager at DEF Corporation',
	name: 'Michael Kim',
	img: '/images/testimonials/testimonial_1.jpg',
	},
	{
	title: 'Marketing Director at GHI Enterprises',
	desc: "Influencer marketing has been a key part of our marketing strategy, and it has delivered excellent results. The team at InfluencerPros helped us identify the right influencers for our brand, and the content they created was fantastic. We look forward to continuing our partnership with them.",
	id: '3Marketing Director at GHI Enterprises',
	name: 'Samantha Lee',
	img: '/images/testimonials/testimonial_1.jpg',
	},
	];