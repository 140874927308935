import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, EffectCoverflow, Ally } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import { coverflow_data } from "../../data/coverflow_data";
import Link from "next/link";
import Image from "next/image";
import { useDispatch, useSelector } from "react-redux";
import { getHeroInfluencersAsync } from "../../redux/actions";
import { showHeroes, fetchingHeroInfluencers } from "../../redux/counterSlice";
import CountUp from "react-countup";
import React, { useState, useEffect } from "react";
import Skeleton from "@mui/material/Skeleton";
const CoverflowCarousel = () => {
  const { influencers } = useSelector(showHeroes);
  const fetching = useSelector(fetchingHeroInfluencers).payload.counter
    .fetchingHeroInfluencers;

  const dispatch = useDispatch();

  const [heroes, setHeroes] = useState(["", "", "", "", "", "", ""]);

  useEffect(() => {
    if (influencers) {
      let heroData = [];
      influencers.map((x) => {
        const nonAvatarArr = x.images.filter((x) => x.key !== "avatar");
      
        heroData = [
          ...heroData,
          {
            id: x.uid,
            subtext:
              x.instagram.username.length > 0
                ? "@" + x.instagram.username
                : x.userInfo.bio,
            authorImage: x.images[0]?.imageURL || x.images[0]?.fileURL,
            title: x.userInfo.username,
            img: x.images[1].imageURL || x.images[1].fileURL,
            // nonAvatarArr[Math.floor(Math.random() * x.images.length)]?.imageURL,
            followers: x.followers,
          },
        ];
      });

      setHeroes([...heroData]);
    }
  }, [influencers]);

  useEffect(() => {
    dispatch(getHeroInfluencersAsync());
  }, []);
  return (
    <>
      {/* <!-- Coverflow Slider --> */}
      <div className="relative px-6 sm:px-0">
        {/* <!-- Slider --> */}
        <Swiper
          breakpoints={{
            // when window width is >= 640px
            100: {
              // width: 640,
              slidesPerView: 1,
            },
            575: {
              // width: 640,
              slidesPerView: 3,
            },
            // when window width is >= 768px
            992: {
              // width: 768,
              slidesPerView: 5,
            },
          }}
          effect={"coverflow"}
          grabCursor={true}
          centeredSlides={true}
          slidesPerView={5}
          loop={true}
          coverflowEffect={{
            rotate: 30,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: true,
          }}
          pagination={true}
          modules={[EffectCoverflow, Pagination, Navigation]}
          navigation={{
            nextEl: ".swiper-button-next-4",
            prevEl: ".swiper-button-prev-4",
          }}
          className="swiper coverflow-slider !py-5 bg-jacarta-900"
        >
          {heroes.map((item, key) => {
            if (fetching) {
              return (
                <SwiperSlide key={key}>
                  <article>
                    <Skeleton
                         sx={{ bgcolor: "grey.700", transform: 'initial', transformOrigin:'initial' }}
                      height={450}
                      width={380}
                    />
                  </article>
                </SwiperSlide>
              );
            }
            const { img, id, authorImage, subtext, title, followers } =
              item || "";

            // const itemLink = img
            //   .split("/")
            //   .slice(-1)
            //   .toString()
            //   .replace(".jpg", "")
            //   .replace(".gif", "")
            //   .replace("_lg", "");
            return (
              <SwiperSlide key={key}>
                <article>
                  <div className="block overflow-hidden rounded-2.5xl bg-white shadow-md transition-shadow hover:shadow-lg dark:bg-jacarta-700">
                    <figure className="relative">
                      <Link href={"/influencers/" + id}>
                        <a>
                          <img
                            src={img}
                            alt={title}
                            className="swiper-lazy h-[430px] w-full object-cover"
                            height="430"
                            width="379"
                          />
                        </a>
                      </Link>
                    </figure>
                    <div className="p-6">
                      <div className="flex">
                        <Link href={"/influencers/" + id}>
                          <a className="shrink-0">
                            <img
                              src={authorImage}
                              alt="avatar"
                              className="mr-4 h-10 w-10 rounded-full"
                            />
                          </a>
                        </Link>
                        <div>
                          <Link href={"/influencers/" + id}>
                            <a className="block">
                              <span className="font-display text-lg leading-none text-jacarta-700 hover:text-accent dark:text-white">
                                {title}
                              </span>
                            </a>
                          </Link>
                          <Link href={"/influencers/" + id}>
                            <a className="text-2md text-accent">
                              <div
                               className="descText"
                              >
                                {subtext}
                              </div>
                            </a>
                          </Link>
                        </div>
                        {/* <span style={{ flex: 1, textAlign: "right" }}>
                          <span className="dark:border-jacarta-600 group bg-accent border-jacarta-100 font-display   items-center rounded-lg border px-4 py-2 text-sm font-semibold transition-colors border-transparent text-white dark:border-transparent capitalize">
                            <CountUp
                              className=""
                              start={1}
                              duration={2}
                              end={parseInt(
                                followers / 1000 > 999
                                  ? followers / 1000000
                                  : followers / 1000
                              )}
                            >
                              {" "}
                            </CountUp>
                            {followers / 1000 > 999 ? "M" : "k"}+
                          </span>
                          <br />
                          <div className="mt-2">followers</div>
                        </span> */}
                      </div>
                    </div>
                  </div>
                </article>
              </SwiperSlide>
            );
          })}
        </Swiper>

        <div className="swiper-button-prev-4 group absolute top-1/2 left-4 z-10 -mt-6 flex h-12 w-12 cursor-pointer items-center justify-center rounded-full bg-white p-3 text-jacarta-700 text-xl shadow-white-volume">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
            className="fill-jacarta-700 group-hover:fill-accent"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path d="M10.828 12l4.95 4.95-1.414 1.414L8 12l6.364-6.364 1.414 1.414z" />
          </svg>
        </div>
        <div className="swiper-button-next-4 group absolute top-1/2 right-4 z-10 -mt-6 flex h-12 w-12 cursor-pointer items-center justify-center rounded-full bg-white p-3 text-jacarta-700 text-xl shadow-white-volume">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
            className="fill-jacarta-700 group-hover:fill-accent"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" />
          </svg>
        </div>

        {/* <!-- end coverflow slider --> */}
      </div>
    </>
  );
};

export default CoverflowCarousel;
