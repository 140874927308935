import React from 'react';
import {
	Hero,
	Bids,
	Tranding_category,
	NewseLatter,
	Browse_category
} from '../../components/component';
import CoverflowCarousel from '../../components/carousel/coverflowCarousel';
import Benifits from "../../components/dao/Benifits";
import Meta from '../../components/Meta';
import ComingSoon from '../../pages/ComingSoon';
import Testimonial from '../../components/testimonial/Testimonial';
import Hero_5 from '../../components/hero/hero_5';
import Financialnews from '../../components/blog/financialnews';


const Home_1 = () => {
	return (
		<main>
			<Meta title="Marketing agency for web3 projects" />
	     {/* <ComingSoon/>  */}
			<Hero_5/>
			{/* <CoverflowCarousel />
			<Bids /> */}
	
			<Browse_category/>
			<NewseLatter/>
			<Benifits/>
			<Testimonial/>
	         <Financialnews/>
		
		</main>
	);
};

export default Home_1;
