import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import { financial_data } from '../../data/financial_data';
import Link from 'next/link';
import { blogData } from '../../pages/single_post/[single_post]';

const Financial_carousel = () => {
	return (
		<>
			<Swiper
				modules={[Pagination]}
				pagination={{ clickable: true }}
				spaceBetween={30}
				slidesPerView="auto"
				loop={true}
				breakpoints={{
					240: {
						slidesPerView: 1,
					},
					565: {
						slidesPerView: 2,
					},
					995: {
						slidesPerView: 3,
					},
				}}
				className=" card-slider-4-columns !py-5"
			>
				{blogData.map((item) => {
					const { id, image, title, text, date, time } = item;
					return (
						<SwiperSlide className="text-white overflow-visible" key={id}>
							<article key={id}>
							<div className="rounded-2xl overflow-hidden transition-shadow hover:shadow-lg">
								<figure className="group overflow-hidden ">
									<Link href={`/single_post/${id}`}>
										<a>
											<img
												src={image}
												alt={title}
												className="h-full w-full object-cover transition-transform duration-[1600ms] will-change-transform group-hover:scale-105 "
											/>
										</a>
									</Link>
								</figure>

								{/* <!-- Body --> */}
								<div className="dark:border-jacarta-600 dark:bg-jacarta-700 border-jacarta-100 rounded-b-[1.25rem] border border-t-0 bg-white p-[10%]">
									{/* <!-- Meta --> */}
									<div className="mb-3 flex flex-wrap items-center space-x-1 text-xs">
										<a
											href="#"
											className="dark:text-jacarta-200 mb-[-2px] text-jacarta-700 font-display hover:text-accent-light"
										>
											Admin
										</a>
										<span className="dark:text-jacarta-400">{'>'}</span>
										<span className="text-accent-light inline-flex flex-wrap items-center space-x-1">
										<a href="#">{"#blog"}</a>
												<a href="#">#news</a>
										</span>
									</div>

									<h2 className="font-display transition-all text-jacarta-700 dark:hover:text-accent-light hover:text-accent-light mb-4 text-xl dark:text-white">
									<Link href={`/single_post/${id}`}>
											<a> {title} </a>
										</Link>
									</h2>
									<p className="dark:text-jacarta-200 mb-8">{text}</p>

									{/* <!-- Date / Time --> */}
									<div className="text-jacarta-400 flex flex-wrap items-center space-x-2 text-sm">
										<span>
											<time>{date}</time>
										</span>
										<span>•</span>
										<span>{time}</span>
									</div>
								</div>
							</div>
						</article>
						</SwiperSlide>
					);
				})}
			</Swiper>
		</>
	);
};

export default Financial_carousel;
