import BeniftContent from "./BeniftContent";
import { useTranslation } from "react-i18next";
const Benifits = () => {
  const {t} = useTranslation()
  return (
    <>
      {/* <!-- Benefits --> */}
      <section className="py-6 md:py-16 dark:bg-jacarta-900">
        <div className="container">
          <div className="mx-auto  mb-12 md:mb-16 pt-6 max-w-xl text-center">
            <h2 className="mb-1 text-center font-display text-3xl font-medium text-jacarta-700 dark:text-white">
           {t('benefits.title')}
            </h2>
            <p className="text-lg dark:text-jacarta-300">
            {t('benefits.subtitle')}
            </p>
          </div>
          <BeniftContent />
        </div>
      </section>
      {/* <!-- end benefits --> */}
    </>
  );
};

export default Benifits;
