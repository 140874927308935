import { axiosInstance } from "../utils/Axios"
import {getHeroInfluencers, fetchingHeroInfluencers} from './counterSlice'


    export const getHeroInfluencersAsync = () => async (dispatch) => {
        try {

          dispatch(fetchingHeroInfluencers())
         
         const response = await axiosInstance.get('/influencers/hero');
           
          dispatch(getHeroInfluencers(response.data));
        } catch (err) {
          throw new Error(err);
        }
      };