import Image from "next/image";
import Link from "next/link";
import React from "react";
import Meta from "../components/Meta";

const ComingSoon = () => {
  return (
    <div>
      <Meta title="404 || Page not found" />
      <div className="pt-[5.5rem] lg:pt-24">
        {/* <!-- 404 --> */}
        <section className="dark:bg-jacarta-800 relative py-16 md:py-24">
          <picture className="pointer-events-none absolute inset-0 -z-10 dark:hidden">
            <img src="/images/gradient_light.jpg" alt="gradient" />
          </picture>

          <div className="container">
            <div className="mx-auto max-w-auto px-5 text-center">
              <span className="mb-14 inline-block">
                <img src="/images/404.png" alt="gradient" />
              </span>
              <h1 className="text-jacarta-700 font-display mb-2 text-4xl dark:text-white md:text-6xl">
                Page Under construction!
              </h1>
              <p className="dark:text-jacarta-300 mb-12 text-lg leading-normal">
                We are working on bringing it back up!
              </p>
            
            </div>
          </div>
        </section>
        {/* <!-- end 404 --> */}
      </div>
    </div>
  );
};

export default ComingSoon;
